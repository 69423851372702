import React from 'react';
import { Box, Typography } from '@mui/material';
import Section from '../components/Section';

const SeccionDescarga = () => {
  return (
    <Section
      title="¿Cómo descargar la app?"
      hasBackground={false}
    >
      <Box sx={{textAlign: 'justify'}}>
        <Typography 
          variant="body1" 
          component="div" 
          sx={{ mb: 2, textAlign: { xs: 'center', sm: 'left' }, fontSize: { xs: '16px', sm: '18px' } }} // Ajuste de alineación y tamaño de texto
        >
          <strong>Mi Rifa App</strong> está disponible para dispositivos Android y iOS, ¡así que puedes llevar tus rifas contigo a donde vayas! Descárgala ahora desde tu tienda favorita y comienza a disfrutar de una experiencia de gestión de rifas fácil y eficiente.
        </Typography>
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            gap: 2, 
            flexDirection: { xs: 'column', sm: 'row' }
          }} 
        >
          <a href="https://play.google.com/store/apps/details?id=com.rauhtech.rauhapp&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
            <img 
              src="/assets/imgs/play-store.png" 
              alt="Descargar en Google Play" 
              style={{ maxWidth: '100%', height: 'auto', width: '300px' }}
              loading='lazy'
            />
          </a>
          <a href="/" target="_blank" rel="noopener noreferrer">
            <img 
              src="/assets/imgs/app-store.png" 
              alt="Descargar en App Store" 
              style={{ maxWidth: '100%', height: 'auto', width: '300px' }}
              loading='lazy'
            />
          </a>
        </Box>
      </Box>
    </Section>
  );
};

export default SeccionDescarga;
