import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import Section from './Section';

const SeccionCambiarEstado = () => {
  return (
    <Section
      title="¿Cómo cambiar el estado de un boleto?"
      hasBackground={false}
    >
      <Box sx={{ textAlign: 'justify' }}>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          Cambiar el estado de un boleto en <strong>Mi Rifa App</strong> es muy sencillo. Solo sigue estos pasos:
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="1. Ingresa a la app y selecciona la rifa previamente creada." />
          </ListItem>
          <ListItem>
            <ListItemText primary="2. Ve a la pestaña llamada 'ventas'." />
          </ListItem>
          <ListItem>
            <ListItemText primary="3. Busca el número que deseas modificar." />
          </ListItem>
          <ListItem>
            <ListItemText primary="4. Presiona el botón con los tres puntos horizontales (...) que está a la derecha." />
          </ListItem>
          <ListItem>
            <ListItemText primary="5. Se abrirá un formulario. Selecciona el nuevo estado del boleto." />
          </ListItem>
          <ListItem>
            <ListItemText primary="6. Presiona el botón 'Actualizar'." />
          </ListItem>
          <ListItem>
            <ListItemText primary="7. Se enviará una notificación vía WhatsApp al comprador notificando el nuevo estado del boleto." />
          </ListItem>
        </List>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: 5,
            mt: 2
          }}
        >
          <Box
            component="img"
            sx={{
              maxWidth: '250px',
              height: 'auto'
            }}
            alt="Cambiar estado 1"
            src='/assets/imgs/vender-1.jpg'
            loading='lazy'
          />
          <Box
            component="img"
            sx={{
              maxWidth: '250px',
              height: 'auto'
            }}
            alt="Cambiar estado 2"
            src='/assets/imgs/estado-1.jpg'
            loading='lazy'
          />
          <Box
            component="img"
            sx={{
              maxWidth: '250px',
              height: 'auto'
            }}
            alt="Cambiar estado 2"
            src='/assets/imgs/estado-2.jpg'
            loading='lazy'
          />
          <Box
            component="img"
            sx={{
              maxWidth: '250px',
              height: 'auto'
            }}
            alt="Cambiar estado 3"
            src='/assets/imgs/estado-3.jpg'
            loading='lazy'
          />
        </Box>
      </Box>
    </Section>
  );
};

export default SeccionCambiarEstado;
