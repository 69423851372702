import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { desencriptar } from '../utilities/encriptado';
import { Typography, Card, CardMedia, CardContent, CircularProgress, Grid2, List, Box } from '@mui/material';
import InfoListItem from '../components/InfoListItem';
import Tablero from '../components/Tablero';

const MiRifa = () => {
  const { id } = useParams();
  const [dataRifa, setDataRifa] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/rifas/mirifa/${id}`);
      const data = desencriptar(response.data);
      if (data && data.length > 0) {
        setDataRifa(data[0]);
      } else {
        setError("Página no encontrada");
      }
    } catch (error) {
      setError("Error al obtener los datos de la rifa");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    } else {
      setLoading(false);
      setError("Página no encontrada");
    }
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  if (!dataRifa) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundImage: `url('/assets/imgs/fondo.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        px: { xs: 0, md: 5 },
        paddingTop: { xs: 10, md: 3 },
        paddingBottom: 3
      }}
    >
      <Grid2 container spacing={{ xs: 1, md: 5 }}>
        <Grid2 size={{ xs: 12, md: 7 }}>
          <Card
            sx={{
              marginTop: { md: 10 },
              width: '100%',
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: 180, md: 220 },
                objectFit: 'cover',
              }}
              image={`${process.env.REACT_APP_URL_FILES}${dataRifa.rutaArchivo}`}
              title={dataRifa.titulo}
              loading='lazy'
            />
            <CardContent
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0)',
              }}
            >
              <Typography variant="h5" gutterBottom>
                {dataRifa.titulo}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {dataRifa.descripcion}
              </Typography>
              <List>
                <InfoListItem icono="confirmation_number" titulo="Lotería" descripcion={dataRifa.nombre_loteria} />
                <InfoListItem icono="event_available" titulo="Fecha del sorteo" descripcion={dataRifa.fecha_sorteo} />
                <InfoListItem icono="local_atm" titulo="Valor de la rifa" descripcion={dataRifa.precio} />
                <InfoListItem icono="redeem" titulo="Premio" descripcion={dataRifa.premio} />
              </List>
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 5 }} sx={{ padding: { xs: 1 }, marginTop: { md: 10 } }}>
          <Tablero rifa={dataRifa} />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default MiRifa;
