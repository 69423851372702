import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import Section from './Section';

const SeccionPersonalizar = () => {
  return (
    <Section
      title="¿Cómo personalizar el tablero de tu rifa?"
      hasBackground={false}
    >
      <Box sx={{ textAlign: 'justify' }}>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          La funcionalidad de personalización del tablero en <strong>Mi Rifa App</strong> está disponible exclusivamente para <strong>suscriptores Premium</strong>. Con esta opción, puedes personalizar el aspecto de tu rifa de la siguiente manera:
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="1. Abre la app, selecciona la rifa que deseas personalizar y ve a la pestaña 'personalizar'." />
          </ListItem>
          <ListItem>
            <ListItemText primary="2. Encontrarás tres secciones para personalizar:" />
          </ListItem>
        </List>

        <Typography variant="body1" component="div" sx={{ mb: 2, ml: 4 }}>
          <strong>Rifas Disponibles:</strong> Puedes cambiar el color de fondo y el color del texto para que se adapten a tu estilo.
        </Typography>

        <Typography variant="body1" component="div" sx={{ mb: 2, ml: 4 }}>
          <strong>Rifas Reservadas:</strong> Permite cambiar el color de fondo, el color del texto y añadir un emoji para representar mejor el estado de reserva.
        </Typography>

        <Typography variant="body1" component="div" sx={{ mb: 2, ml: 4 }}>
          <strong>Rifas Pagadas:</strong> También puedes ajustar el color de fondo, el color del texto y añadir un emoji para diferenciar visualmente las rifas que ya han sido pagadas.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: 5,
            mt: 2
          }}
        >
          <Box
            component="img"
            sx={{
              maxWidth: '250px',
              height: 'auto'
            }}
            alt="Personalizar 1"
            src='/assets/imgs/personalizar-1.jpg'
            loading='lazy'
          />
          <Box
            component="img"
            sx={{
              maxWidth: '250px',
              height: 'auto'
            }}
            alt="Personalizar 2"
            src='/assets/imgs/personalizar-2.jpg'
            loading='lazy'
          />
          <Box
            component="img"
            sx={{
              maxWidth: '250px',
              height: 'auto'
            }}
            alt="Personalizar 3"
            src='/assets/imgs/personalizar-3.jpg'
            loading='lazy'
          />
          <Box
            component="img"
            sx={{
              maxWidth: '250px',
              height: 'auto'
            }}
            alt="Personalizar 4"
            src='/assets/imgs/personalizar-4.jpg'
            loading='lazy'
          />
        </Box>
      </Box>
    </Section>
  );
};

export default SeccionPersonalizar;
